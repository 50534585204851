import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import Container from '../../layouts/container';
import ProjectBreadcrumbs from '../../components/project-breadcrumbs';
import HorizontalRule from '../../components/horizontal-rule';
import Testimonial from '../../components/testimonial';

import { formatDateWithMonth } from '../../utils';

import videoNovartisAni from '../../assets/media/novartis_ani.webm';

import styles from './projects.module.scss';

// NOTA BENE: Do better than this in future...
const PAGE = 1;

const PageProject = ({ data }) => {
  const { title, date, thumbnail } = data.pagesJson.portfolio.projects[PAGE];

  return (
    <Container>
      <ProjectBreadcrumbs>{title}</ProjectBreadcrumbs>
      <p className={styles.date}>{formatDateWithMonth(new Date(date))}</p>
      <h1 className={styles.projectTitle}>{title}</h1>

      <section className={styles.headerWithImage}>
        <div className={styles.headerContent}>
          <p className={classNames(styles.paragraph, styles.summary)}>Redburn has over fifty publishing research analysts, working across a wide variety of topics, from Biopharma to Banking. Many are specialists in their chosen subject, as well as being financial experts. However, the clients reading their research will usually have little to no specialist knowledge. I therefore assist analysts to produce graphics to accompany their reports that show complex processes in an easy to understand format. These can be either illustrations or animated sequences.</p>
          <Testimonial attribution="Research Analyst">Thank you for the fantastic effort you’ve made to get this over the line. The care and attention you place on product far outstrips what I have experienced elsewhere.</Testimonial>
        </div>
        <Img className={styles.headerImage} fluid={data.headerImage.childImageSharp.fluid} />
      </section>


      <HorizontalRule />

      <section className={styles.projectBody}>
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Role</h2>
          <p className={styles.paragraph}>I work as the lead designer, alongside my colleagues in the Editorial & Production Team, to produce a variety of ad-hoc illustrations and animated videos to accompany our analysts’ reports, helping to bring complex research topics to life and inspire debate.</p>
          <p className={classNames(styles.paragraph, styles.italic)}>Please note: to maintain company and client confidentiality, I am unable to publicly release full content. The details shown below are for illustrative purposes only and should not be shared. All views in this case study are my own and do not necessarily reflect those of Redburn.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Problem</h2>
          <p className={styles.paragraph}>In the case study illustrated here, the analyst was writing a report on the role of gene therapy in medicine. Understandably, very few of Redburn’s clients are experts in this field, so the language in the report and any accompanying illustrations needed to be as easy to understand as possible.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Design</h2>
          <p className={styles.paragraph}>I produced a number of illustrations for the report. For some, the analyst had a rough idea in his head of what he wanted to show, which was then worked up through a series of iterations to produce the final version. The emphasis was on helping clients to understand a complex scientific process without them feeling overwhelmed by the detail.</p>
          <p className={styles.paragraph}>I reduced each element to as simple a form as possible, using our yellow highlight colour to draw the eye to the relevant part of each section. I then worked with the Editorial & Production Team to ensure that the tone used matched the rest of the report, and that the correct styles and sizings were adhered to.</p>
          <div className={styles.imageContainer}>
            <Img fluid={data.pharma_double_01.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>For others, the analyst had an illustration from a scientific publication to work from. Once I’d checked with the analyst that the appropriate copyright permissions had been obtained, I simplified the illustration and restyled it in our colours and styles so that it would fit with the rest of the report.</p>
          <div className={styles.imageContainer}>
            <Img fluid={data.pharma_double_02.childImageSharp.fluid} />
          </div>
          <p className={styles.paragraph}>Finally, I built an animated sequence to accompany voiceover by the analyst. This was exported for use both as part of his presentation to clients and as a standalone video snippet for future marketing purposes.</p>
          <video
            controls
            poster={data.pharma_video_thumbnail.childImageSharp.fluid.src}
            src={videoNovartisAni}
          />
          <p className={classNames(styles.paragraph, styles.italic)}>Video shortened for copyright reasons.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Outcome</h2>
          <p className={styles.paragraph}>On publication, our Head of Sector Research commented that he thought the animation was “a really nice touch, very professional and helpful to the user”. The report was also featured in Redburn’s quarterly round up of high-quality content.</p>
        </section>
      </section>
    </Container>
  );
};

export const imageQuery = graphql`
  fragment imageQuery on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
{
  pagesJson(portfolio: {projects: {elemMatch: {slug: {regex: "/-/"}}}}) {
    portfolio {
      projects {
        slug
        thumbnail
        title
        date
      }
    }
  }
  headerImage: file(relativePath: { eq: "projects/pharma-mockup.png" }) {
    ...imageQuery
  }
  pharma_double_01: file(relativePath: { eq: "projects/pharma-double-01.png" }) {
    ...imageQuery
  }
  pharma_double_02: file(relativePath: { eq: "projects/pharma-double-02.png" }) {
    ...imageQuery
  }
  pharma_video_thumbnail: file(relativePath: { eq: "projects/pharma-video-thumbnail.png" }) {
    ...imageQuery
  }
}
`;

export default PageProject;
